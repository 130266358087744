import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >

<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M513.782349,352.098389 
	C518.158081,351.877808 519.337280,353.836945 519.331665,357.598694 
	C519.251404,411.239014 519.246826,464.879517 519.337952,518.519775 
	C519.344482,522.367920 518.058044,524.079895 514.192078,523.923462 
	C511.198456,523.802246 508.180847,523.705017 505.201019,523.940491 
	C500.428131,524.317627 498.431610,522.250122 498.756256,517.557617 
	C498.845825,516.262695 498.527222,514.939514 498.405548,513.725647 
	C496.792847,512.850220 496.075500,514.089783 495.258850,514.755005 
	C472.655945,533.166687 444.399048,529.291687 427.950439,505.500519 
	C411.810059,482.155212 412.919403,439.416443 430.244293,417.125732 
	C444.948975,398.206268 473.985535,394.652405 492.920990,409.449951 
	C493.943176,410.248779 494.997192,411.006989 496.132416,411.855927 
	C497.944183,409.466553 497.603790,406.930298 497.609924,404.514740 
	C497.649139,389.022400 497.722839,373.529083 497.574524,358.038086 
	C497.537079,354.125580 498.463531,352.113770 502.857025,352.430481 
	C506.321777,352.680237 509.836853,352.231476 513.782349,352.098389 
M490.773682,426.720734 
	C489.723969,425.903046 488.715302,425.025269 487.618439,424.276672 
	C471.786102,413.470856 451.692169,418.551331 444.055237,436.015839 
	C436.306549,453.735870 436.349487,472.097687 443.731598,489.912811 
	C450.699615,506.728790 469.109467,512.500854 485.002777,503.420013 
	C493.089020,498.799805 498.294800,492.495544 497.794739,482.127625 
	C497.169647,469.168396 497.345642,456.146484 497.767578,443.170868 
	C497.980682,436.618713 495.989838,431.500458 490.773682,426.720734 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M300.860046,421.936462 
	C307.999451,403.214691 315.105225,384.891724 321.926086,366.463318 
	C323.449768,362.346680 325.747528,360.824554 330.053528,360.914459 
	C346.250336,361.252594 342.627472,358.966309 348.052429,373.009491 
	C366.413635,420.540100 384.596497,468.139648 402.828674,515.720093 
	C403.654785,517.876038 404.296936,520.102478 405.227997,522.908569 
	C397.824707,524.683350 390.780670,524.153259 383.782715,523.406189 
	C381.708801,523.184753 381.462646,520.770386 380.848633,519.090698 
	C376.846069,508.140930 372.772461,497.214752 368.971954,486.194702 
	C367.760834,482.682983 365.982239,481.276398 362.136963,481.312317 
	C343.143768,481.489655 324.147369,481.448914 305.153381,481.312012 
	C301.802795,481.287872 300.101257,482.332306 298.981171,485.603363 
	C295.205627,496.629456 290.936768,507.487335 287.208405,518.528381 
	C285.865875,522.504150 283.763031,524.239014 279.612946,524.003723 
	C275.792480,523.787048 271.948853,524.018433 268.119171,523.914490 
	C262.742401,523.768616 262.229340,522.978821 264.147644,517.966675 
	C272.541229,496.035492 280.964417,474.115601 289.358704,452.184692 
	C293.168762,442.230530 296.936554,432.260162 300.860046,421.936462 
M350.577576,435.865601 
	C345.202820,421.225739 339.828094,406.585907 334.453369,391.946045 
	C334.027588,392.007996 333.601837,392.069946 333.176056,392.131897 
	C324.688477,415.429291 316.200897,438.726715 307.653442,462.188446 
	C325.508057,462.188446 342.438812,462.188446 360.084961,462.188446 
	C356.798340,453.114319 353.808746,444.860199 350.577576,435.865601 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M645.421509,468.473206 
	C643.020996,469.721710 640.824524,469.371368 638.681641,469.373444 
	C616.199036,469.395233 593.716431,469.371521 571.233826,469.373016 
	C565.237427,469.373413 564.455322,470.300598 565.730957,476.050110 
	C568.091553,486.690063 571.961060,496.541138 581.539368,502.842499 
	C595.259521,511.868622 614.354919,509.183777 626.851379,496.697052 
	C631.867859,491.684479 631.867859,491.684509 637.504089,496.192169 
	C645.448242,502.545654 645.455017,502.552368 638.265442,509.796753 
	C620.727966,527.467896 599.533447,530.231812 577.424194,522.049255 
	C555.334412,513.873962 545.271912,495.747040 543.675720,472.745789 
	C542.687561,458.506348 543.533936,444.475464 549.756775,431.264923 
	C561.892090,405.502869 589.618164,394.070099 616.386353,403.814667 
	C623.461426,406.390228 629.334167,410.663055 633.686462,416.822784 
	C644.609680,432.282379 647.216187,449.734344 645.421509,468.473206 
M575.916077,427.413239 
	C570.808533,433.000854 568.281677,439.800964 566.664551,447.037567 
	C566.076904,449.666962 566.141907,451.697754 569.707153,451.690125 
	C586.849182,451.653412 603.991394,451.673340 621.133545,451.683289 
	C623.714050,451.684784 624.593628,450.641418 624.201782,447.941925 
	C623.209167,441.105042 621.201904,434.678986 617.707397,428.692932 
	C614.732056,423.596252 610.224792,420.513855 604.636902,419.508514 
	C594.190247,417.629120 584.379639,418.970337 575.916077,427.413239 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M673.494629,426.468811 
	C668.490479,418.853973 663.690430,411.537048 658.008606,402.876068 
	C666.889160,402.876068 674.254456,402.757141 681.611328,402.953949 
	C683.624084,403.007812 684.323792,405.253723 685.253235,406.782562 
	C691.655212,417.313171 698.006958,427.874298 704.377930,438.423767 
	C705.659058,440.545074 706.953430,442.658356 708.684814,445.503448 
	C711.977844,440.217224 714.942383,435.503052 717.862244,430.761353 
	C722.839478,422.678406 727.856140,414.618347 732.712036,406.462830 
	C734.341431,403.726135 736.362671,402.540863 739.602905,402.648865 
	C745.885254,402.858215 752.179504,402.710052 758.178223,402.710052 
	C759.463562,405.562592 757.705933,406.779327 756.755615,408.220367 
	C745.937500,424.625458 735.191833,441.080109 724.175476,457.351318 
	C721.700562,461.006775 721.376770,463.588928 724.045593,467.466064 
	C735.565002,484.200592 746.687134,501.208832 757.923462,518.137573 
	C758.822144,519.491455 760.129272,520.677063 760.176086,522.776733 
	C757.502991,524.937256 754.216675,523.461182 751.270264,523.999146 
	C740.436218,525.976929 733.856323,521.586853 728.934143,511.717346 
	C723.466003,500.752991 716.259094,490.655823 709.303040,479.359070 
	C707.336304,482.401123 705.715698,484.811523 704.191711,487.281555 
	C697.632935,497.911499 690.975281,508.484009 684.635010,519.243225 
	C682.589966,522.713562 680.096924,524.222290 676.161438,523.963623 
	C670.259827,523.575684 664.274231,524.586792 658.363464,523.313965 
	C658.104248,520.297607 659.952881,518.607605 661.170898,516.766357 
	C672.197937,500.098206 683.231873,483.432831 694.491150,466.921661 
	C696.751953,463.606384 696.646729,461.177277 694.450500,457.970123 
	C687.393494,447.664703 680.596252,437.181396 673.494629,426.468811 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M259.750732,534.228882 
	C259.897369,530.989502 261.812531,530.667969 263.965637,530.676392 
	C271.124817,530.704529 278.339355,530.179382 285.430939,530.886597 
	C297.834351,532.123596 307.146637,538.493530 311.559937,550.377991 
	C315.732391,561.613892 315.740540,573.327820 312.071533,584.696228 
	C307.715759,598.192505 296.501587,605.306213 279.806580,605.934448 
	C274.984650,606.115967 270.141785,605.799133 265.324799,606.038391 
	C260.927887,606.256836 259.602966,604.427551 259.636322,600.142029 
	C259.806000,578.331360 259.722321,556.518738 259.750732,534.228882 
M270.672180,566.665039 
	C270.743195,573.826843 270.858215,580.988464 270.873962,588.150391 
	C270.892944,596.799011 270.856476,596.799072 279.757141,596.682068 
	C293.016693,596.507751 300.432709,590.192932 302.950195,577.198853 
	C303.915131,572.218323 303.892151,567.237244 303.426758,562.270447 
	C302.635529,553.825806 300.143616,546.119446 291.889496,541.960022 
	C286.694946,539.342468 280.947113,539.462097 275.262024,539.386658 
	C272.051208,539.343994 270.819733,540.539001 270.867706,543.790649 
	C270.975861,551.119690 270.807007,558.452820 270.672180,566.665039 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M733.068604,583.820679 
	C738.540710,595.999756 748.324341,600.457581 760.556519,596.678101 
	C765.311707,595.208923 768.050476,592.767578 767.785950,587.033142 
	C767.321899,576.973206 767.671082,577.020386 757.385864,576.898682 
	C754.470459,576.864197 750.688293,578.032349 750.586670,572.796997 
	C750.518494,569.282959 751.529968,567.542908 755.370117,567.665405 
	C761.527405,567.862061 767.696777,567.792419 773.858521,567.685730 
	C776.987671,567.631653 778.407654,568.796082 778.346619,572.064026 
	C778.219055,578.891479 778.234619,585.724365 778.352112,592.552307 
	C778.404053,595.566711 776.898987,597.541077 774.780334,599.319824 
	C764.418274,608.019409 752.561340,608.857605 740.446594,604.710815 
	C728.585632,600.650940 722.641479,591.142151 720.879028,579.102295 
	C719.546448,569.999268 719.620361,560.786804 721.866211,551.814697 
	C724.839233,539.937805 732.139771,532.193481 744.536926,530.038086 
	C757.573792,527.771484 768.386902,532.013489 774.303528,541.523743 
	C776.576294,545.177002 777.378235,547.878906 777.493408,552.271301 
	C770.742859,554.623840 768.475220,553.860657 765.889771,547.880310 
	C763.169983,541.589294 758.189758,538.790222 751.703857,538.706543 
	C745.160583,538.622131 739.080139,540.002502 735.460815,546.404968 
	C733.717529,549.488892 732.393066,552.715088 732.008484,556.233215 
	C731.012451,565.344055 730.293457,574.459778 733.068604,583.820679 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M372.731873,599.211853 
	C367.663300,586.315491 367.663300,586.315125 354.203613,586.320435 
	C350.207031,586.322021 346.200409,586.501831 342.217072,586.273804 
	C338.996826,586.089539 337.284637,587.410461 336.364807,590.383240 
	C335.381927,593.559937 334.149994,596.659180 333.048218,599.799622 
	C330.731659,606.402588 327.952576,607.549072 320.805359,604.752991 
	C320.307190,602.122375 321.914368,599.996155 322.767487,597.735413 
	C330.583344,577.023315 338.546570,556.366577 346.308533,535.634460 
	C347.574524,532.252930 349.152283,530.506531 353.089386,530.565735 
	C356.797668,530.621582 358.299835,532.234253 359.505371,535.436035 
	C367.596130,556.924988 375.855225,578.350586 384.035339,599.805969 
	C384.617981,601.334167 385.553741,602.800964 385.232300,604.566895 
	C378.624512,607.721375 375.427002,606.433044 372.731873,599.211853 
M343.768097,570.089172 
	C343.183533,572.125000 341.824493,573.990417 342.487061,576.871460 
	C348.657806,576.871460 354.931824,576.978394 361.199341,576.822571 
	C363.980957,576.753479 363.723633,574.788452 363.032043,572.843750 
	C361.191772,567.668884 359.396973,562.477478 357.517303,557.317017 
	C356.159393,553.588928 354.694489,549.899841 352.818176,544.992737 
	C349.563171,554.058289 346.811707,561.721313 343.768097,570.089172 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M452.551270,594.481934 
	C451.866730,596.419128 451.331909,597.996277 450.778503,599.566956 
	C448.343719,606.477783 446.128357,607.448669 438.690369,604.822327 
	C437.936005,603.086548 439.007660,601.624512 439.575684,600.129700 
	C447.857544,578.334961 456.220642,556.570801 464.424744,534.746887 
	C465.542419,531.773682 467.183105,530.615173 470.319214,530.611877 
	C473.487579,530.608582 475.653900,531.326843 476.885315,534.591187 
	C485.406128,557.178894 494.047729,579.721069 502.625671,602.287231 
	C502.903320,603.017700 502.914215,603.849609 503.039764,604.577881 
	C495.995392,607.582642 493.026947,606.403076 490.652679,599.844543 
	C485.745331,586.288696 485.745361,586.288940 471.486237,586.302307 
	C468.987030,586.304626 466.487823,586.301453 463.988617,586.304138 
	C455.493073,586.313232 455.493073,586.313843 452.551270,594.481934 
M475.175842,557.226013 
	C473.508209,553.986389 473.564941,549.906250 470.059540,546.579834 
	C466.374268,556.795166 462.914917,566.384338 459.218170,576.631470 
	C467.240631,577.144897 474.229431,577.388916 482.076202,576.500305 
	C479.678894,569.762024 477.576721,563.853271 475.175842,557.226013 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M665.326477,577.853271 
	C665.392151,593.341614 674.281799,600.824951 687.408752,596.919922 
	C695.115845,594.627258 698.534546,589.870422 698.603577,580.702698 
	C698.715210,565.877136 698.740540,551.049133 698.582153,536.224426 
	C698.539917,532.275635 699.580078,530.547668 703.881226,530.573975 
	C708.032410,530.599426 709.403259,532.024048 709.351990,536.118652 
	C709.176880,550.109924 709.299133,564.104736 709.281921,578.098206 
	C709.254517,600.361023 691.013306,612.735046 670.058411,604.695496 
	C660.290833,600.948120 655.026672,593.662598 654.812988,583.349976 
	C654.481812,567.364258 654.827026,551.365601 654.639282,535.375305 
	C654.587402,530.961731 656.905701,530.666992 660.298584,530.615601 
	C663.790100,530.562744 665.434387,531.520874 665.388062,535.395203 
	C665.220764,549.386658 665.324402,563.381348 665.326477,577.853271 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M567.812195,530.698242 
	C587.468384,532.036865 597.744324,549.072266 589.153748,565.322815 
	C585.342041,572.533325 578.753357,575.354370 571.166687,576.193237 
	C566.384766,576.721985 561.540100,576.752808 556.720398,576.855347 
	C547.494080,577.051575 548.410400,575.500732 548.360840,584.986145 
	C548.332092,590.476990 548.192627,595.973083 548.383545,601.457092 
	C548.513123,605.180481 546.549133,606.046204 543.438171,605.922546 
	C540.553833,605.807800 537.669067,605.976013 537.680786,601.668701 
	C537.741516,579.372559 537.730530,557.075989 537.649292,534.779907 
	C537.636597,531.303101 539.463928,530.637573 542.380188,530.663269 
	C550.699036,530.736572 559.019043,530.690247 567.812195,530.698242 
M574.889648,565.602356 
	C580.842590,562.036133 581.710144,556.383179 580.536926,550.363403 
	C579.374390,544.398987 575.453979,540.945251 569.282959,539.955933 
	C563.313660,538.999023 557.345886,539.591187 551.378174,539.489685 
	C549.312744,539.454529 548.373596,540.575317 548.377625,542.600098 
	C548.392029,549.913330 548.384521,557.226501 548.404114,564.539734 
	C548.409546,566.568420 549.495667,567.662781 551.502563,567.602539 
	C559.092041,567.374573 566.808411,568.847778 574.889648,565.602356 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M416.274658,582.999939 
	C416.269287,589.164917 416.062225,594.839783 416.333069,600.491760 
	C416.537659,604.761719 414.627167,605.970459 410.719238,606.001282 
	C406.798859,606.032104 405.549744,604.451904 405.579224,600.613281 
	C405.718475,582.452881 405.469482,564.288208 405.741516,546.130859 
	C405.813873,541.303101 404.146729,539.525146 399.550293,539.609985 
	C395.057526,539.692932 390.557587,539.371338 386.064056,539.440063 
	C382.755249,539.490601 381.711090,537.922485 381.749695,534.839355 
	C381.786896,531.866394 383.067474,530.654724 385.993835,530.662354 
	C402.655548,530.705872 419.317657,530.724365 435.979156,530.649475 
	C439.421722,530.633972 440.147491,532.444885 440.158508,535.384155 
	C440.169617,538.350037 439.005829,539.587158 435.977051,539.481445 
	C431.317169,539.318787 426.635254,539.636353 421.986237,539.357483 
	C417.705261,539.100647 416.088196,540.533386 416.180542,545.012512 
	C416.438141,557.503723 416.275208,570.003540 416.274658,582.999939 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M614.270752,575.999817 
	C614.269775,581.324280 614.561096,586.171814 614.184265,590.966858 
	C613.808350,595.750305 615.776855,596.945801 620.222656,596.778748 
	C628.196838,596.479126 636.192261,596.793579 644.174927,596.644836 
	C647.668579,596.579712 649.340271,597.596497 649.319275,601.432678 
	C649.299255,605.091919 647.570923,605.980957 644.397766,605.964233 
	C632.253662,605.900452 620.108521,605.866455 607.964905,605.958801 
	C604.256409,605.987000 603.057495,604.319885 603.069763,600.793274 
	C603.144226,579.332886 603.152771,557.871826 603.041626,536.411743 
	C603.019897,532.235291 604.368896,530.559692 608.815002,530.545837 
	C613.444885,530.531433 614.370178,532.491882 614.316284,536.571655 
	C614.145081,549.545715 614.263672,562.523499 614.270752,575.999817 
z"/>


        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}